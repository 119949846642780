header {
  background: transparent;
  padding: 15px 0;

  &.fixed-top {
    z-index: 999;
  }

  .navbar-brand {
    padding-top: 0;
  }

  .navbar {
    padding: 0;

    li {
      margin-right: 2.5rem;

      a {
        color: white;
        font-size: $font-0875;
        cursor: pointer;
        text-transform: uppercase;

        text-decoration: none;
        // font-weight: 700;
        span {
          position: relative;
          display: block;
          padding-bottom: 5px;

          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: #337ab7;
            visibility: hidden;
            -ms-transform: scaleX(0);
            transition: transform 0.3s ease-in-out 0s;
          }
        }


        &.active, &:hover {
          color: #337ab7;

          span {
            &:after {
              visibility: visible;
              -ms-transform: scaleX(1);
              transform: scaleX(1);
            }
          }
        }
      }
    }

    .navbar-collapse {
      justify-content: flex-end;

      &.show {
        li {
          a {
            &.active, &:hover {
              span {
                &:after {
                  content: none;
                }
              }
            }
          }
        }
      }
    }

    .navbar-toggler:hover, .navbar-toggler:focus {
      outline: none;

      svg {
        &:focus, &:hover {
          outline: none;
        }
      }
    }
  }

  &.scrolled {
    background: white;

    .logoSVG {
      fill: black;
    }

    .navbar {
      li {
        a {
          color: #333;

          &.active, &:hover {

            color: #337ab7;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    background: #1c4263;
    padding-bottom: 10px;
    .navbar-brand {
      margin-left: -10px;
    }
    .logoSVG {
      fill: black;
      width: 215px;
    }

  }
}
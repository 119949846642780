.culture-section {
  background-color: #e3e3e3;

  .title {
    max-width: 75%;
    margin: 40px auto;
    text-align: center;
  }
}
p.description{
  font-size:1.625rem;
}
.our-vision,.our-mission, .culture-section{
  padding: 50px 0;
  .title{
    text-transform: uppercase;
    font-weight: bold;
  }
  }
.aboutBlock {
  h2 {
    font-weight: bold;
    font-size: 2rem;
  }
  p {
    font-size: 1.2rem;
  }
  @media (max-width: 1199px) {
    h2 {
      font-size: 1.7rem;
    }
    p {
      font-size: 1rem;
    }
  }
}
.about-page{
  .about-description{
    padding: 50px 0;
  }
}

﻿



/* 12px */ $font-075: 0.75rem;
/* 14px */ $font-0875: 0.875rem;
/* 16px */ $font-1: 1rem;
/* 18px */ $font-1125: 1.125rem;
/* 24px */ $font-15: 1.5rem;
/* 24px */ $font-12: 1.2rem;
/* 32px */ $font-2: 2rem;
/* 36px */ $font-2250: 2.250rem;
/* 40px */ $font-25: 2.5rem;
/* 46px */ $font-2875 :2.875rem;
/* 48px */ $font-3 :3rem;
/* 64px */ $font-4: 4rem;



.statistics {
  padding: 80px 0;

  .statisticsBlock {
    text-align: center;

    img {
      width: 60px;
    }

    h2 {
      font-size: 2.6rem;
      color: $color-darkgray;
      font-weight: bold;
      margin: 10px 0;
    }

    h4 {
      font-size: $font-1125;
      font-weight: bold;
      color: #808080;
      margin-bottom: 0;
    }
  }

  @media (max-width: 767px) {
    padding: 80px 0 50px;
    .statisticsBlock {
      margin-bottom: 30px;
    }
  }
}

//main colors

$color-lightblue: #72B1E4;
$color-red: #C20000;
$color-blue: #2490E8;
$color-gray: #f5f5f4;
$color-black: #202020;
$color-orange:#ff6633;
$color-orange2:#ff8216;
$color-darkgray:#4e4e4e;

.bg-gray{
  background-color: $color-gray;
}

@mixin crossMultiLine($font-size: $paragraph-font-size, $line-height: 1.4, $lines-to-show: 3, $excerpt-bg: transparent) {
	background: $excerpt-bg;
	display: block; /* Fallback for non-webkit */
	display: -webkit-box;
	max-height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
	//font-size: $font-size;
	line-height: $line-height;
	-webkit-line-clamp: $lines-to-show;
	/* autoprefixer: off */
	-webkit-box-orient: vertical;
	/* autoprefixer: on */
	overflow: hidden;
	text-overflow: ellipsis;
	@-moz-document url-prefix() {
		overflow: hidden;
		position: relative;
		/*  &:before {
            background: $excerpt-bg;
            bottom: 0;
            position: absolute;
            right: 0;
            float: right;
            content: '\2026';
            margin-left: -3rem;
            width: 3rem;
          }
          &:after {
            content: '';
            background: $excerpt-bg;
            position: absolute;
            height: 50px;
            width: 100%;
            z-index: 1;
          }*/
	}
}

@mixin br($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	-o-border-radius: $radius;
	border-radius: $radius;
}

@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
	-moz-transform: rotate($degrees);
	-ms-transform: rotate($degrees);
	-o-transform: rotate($degrees);
	transform: rotate($degrees);
}

@mixin opacity($opacity) {
	opacity: $opacity;
	-moz-opacity: $opacity;
	-khtml-opacity: $opacity;
	$opacity-ie: ($opacity * 100);
	filter: alpha(opacity=$opacity-ie);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=#{$opacity-ie})";
}

@mixin saturate($saturate) {
	filter: saturate($saturate);
	-webkit-filter: saturate($saturate);
	-moz-filter: saturate($saturate);
	-o-filter: saturate($saturate);
	-ms-filter: saturate($saturate);
}

@mixin user-select($userSelect) {
	user-select: $userSelect;
	-webkit-user-select: $userSelect;
	-moz-user-select: $userSelect;
	-ms-user-select: $userSelect;
	-o-user-select: $userSelect;
}

@mixin translate3d($x, $y, $z, $important:"") {
	-webkit-transform: translate3d($x, $y, $z)#{$important};
	-moz-transform:    translate3d($x, $y, $z)#{$important};
	-ms-transform:     translate3d($x, $y, $z)#{$important};
	-o-transform:      translate3d($x, $y, $z)#{$important};
	transform:         translate3d($x, $y, $z)#{$important};
}

@mixin translate($x, $y, $important:"") {
	-webkit-transform: translate($x, $y)#{$important};
	-moz-transform:    translate($x, $y)#{$important};
	-ms-transform:     translate($x, $y)#{$important};
	-o-transform:      translate($x, $y)#{$important};
	transform:         translate($x, $y)#{$important};
}

@mixin bsize($bSize) {
	background-size: $bSize;
	-webkit-background-size: $bSize;
	-moz-background-size: $bSize;
	-o-background-size: $bSize;
}

@mixin box-shadow($shadow) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
	box-shadow: $shadow;
}

@function toEm($number) {
	@return ($number / 16) + em;
}

@mixin DisplayFlex {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin keyframe ($animation_name) {
	@-webkit-keyframes #{$animation_name} {
		@content;
	}
	
	@-moz-keyframes #{$animation_name} {
		@content;
	}
	
	@-o-keyframes #{$animation_name} {
		@content;
	}
	
	@keyframes #{$animation_name} {
		@content;
	}
}

@mixin animation ($delay, $duration, $animation, $timing-function: linear, $fillmode: forwards, $iteration: initial, $direction: normal) {
	-webkit-animation-delay: $delay;
	-webkit-animation-duration: $duration;
	-webkit-animation-name: $animation;
	-webkit-animation-fill-mode: $fillmode;
	-webkit-animation-timing-function: $timing-function;
	-webkit-animation-iteration-count: $iteration;
	-webkit-animation-direction: $direction;
	
	-moz-animation-delay: $delay;
	-moz-animation-duration: $duration;
	-moz-animation-name: $animation;
	-moz-animation-fill-mode: $fillmode;
	-moz-animation-timing-function: $timing-function;
	-moz-animation-iteration-count: $iteration;
	-moz-animation-direction: $direction;
	
	animation-delay: $delay;
	animation-duration: $duration;
	animation-name: $animation;
	animation-fill-mode: $fillmode;
	animation-timing-function: $timing-function;
	animation-iteration-count: $iteration;
	animation-direction: $direction;
}
.homeSlider {
  height: 100vh;
  background-size: cover;
  position: relative;

  &:after {
    position: absolute;
    background: rgba(0, 0, 0, 1);
    opacity: 0.6;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    //z-index: -1;

  }


}

.carousel-fade {
  .carousel-item {
    opacity: 1;

    .homeSlider {
      &:after{
        -webkit-animation: fadeOut 4s ease forwards;
        animation: fadeOut 4s ease  forwards;
      }
      > div {
        h2 {
          color: #f8f8f8;
          font-size: 42px;
          font-weight: bolder;
          margin: 0;
          text-transform: uppercase;
          -webkit-animation: fadeIn 8s ease .7s forwards;
          animation: fadeIn 8s ease .7s forwards;
          opacity: 0;

          &:last-of-type {
            color: $color-orange2;
          }
        }
      }
    }
  }
}


.swiper-slide-active, .active {
  .homeSlider {


    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //align-items: center;
      height: 100%;
      position: relative;
      z-index: 5;

    }
  }
}

.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 2;

  .indicator {
    border-radius: 20px;
    height: 12px;
    width: 12px;
    margin: 0 3px;
    padding: 0 10px;
    display: inline-block;
    text-indent: -999px;
    cursor: pointer;
    background-color: #000 \9
  ;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #fff;

    &.swiper-pagination-bullet-active {
      background: #337ab7;
      border-color: #337ab7;
    }

    &:focus {
      outline: none;
    }

    > span {

    }
  }
}

footer{
  background: #222;
  color:white;
  padding: 80px 0 80px;
  .logoSVG{
    fill:white;
  }
  p{
    font-size: $font-0875;
    padding: 30px 0;
  }
}

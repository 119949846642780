body, html {
  font-family: 'Sen', sans-serif;
  margin: 0;
  padding: 0;
}

.tellUs-btn {
  background: #14aae4;
  border: 2px solid #14aae4;
  padding: 18px 30px;
  position: relative;
  color: #fff;
  font-family: montserrat, sans-serif;
  font-size: $font-15;
  text-transform: uppercase;
  z-index: 1;
  display: inline-block;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
  @media (max-width: 1199px) {
    font-size: $font-1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 0.9;
  }

  100% {
    opacity: 0.6;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 0.9;
  }

  100% {
    opacity: 0.6;
  }
}

.title {
  span {
    color: $color-orange;
  }
}

.loading {
  position: relative;
  min-height: 80px;

  &.blur {
    &:before {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    display: block;
    background-image: url(../static/images/loader.gif);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 64px 64px;
    -webkit-background-size: 64px 64px;
    -moz-background-size: 64px 64px;
    -o-background-size: 64px 64px;
  }
}

.section-padding {
  padding: 60px 0;
}

.section-title {
  margin-bottom: 60px;

  h2, h3, h4 {
    &.section-title-white {
      color: #fff;
      position: relative;
    }
  }

  p {
    margin: auto;
    padding: 0 20px;
    width: 70%;
    position: relative;
    font-size: 1.25em;
    color: #333;

    &.section-dec-white {
      color: #fff;
    }
  }

  span {
    display: inline-block;
    position: relative;
    margin: 20px auto;
    font-size: 28px;
    text-align: center;

    &.section-title-white-span {
      color: #fff;

      &:before, &:after {
        border-color: #fff;
      }
    }

    &:before, &:after {
      content: "";
      position: absolute;
      height: 5px;
      border-bottom: 1px solid #337ab7;
      border-top: 1px solid #337ab7;
      top: 15px;
      width: 100px;
    }

    &:before {
      right: 100%;
      margin-right: 15px;
    }

    &:after {
      left: 100%;
      margin-left: 15px;
    }
  }
}

.home {
  height: 50vh;
  background-size: cover;
  position: relative;
  display: flex;
  align-items:center;
  justify-content: center;
>div{
  position: relative;
  z-index: 3;
  color: white;
  text-transform: uppercase;
  text-align: center;
}
  &:after {
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 2;
  }
}

.fade-enter{
  opacity: 0;
}
.fade-exit{
  opacity: 1;
}
.fade-enter-active{
  opacity: 1;
}
.fade-exit-active{
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 800ms;
}

.fade-in {
  animation: fadeIn ease 800ms;
  -webkit-animation: fadeIn ease 800ms;
  -moz-animation: fadeIn ease 800ms;
  -o-animation: fadeIn ease 800ms;
  -ms-animation: fadeIn ease 800ms;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.grecaptcha-badge
{
  &.hide {
    opacity: 0;
  }
}


.section-back-image {
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;

  &.contact-area {
    position: relative;
    padding: 70px 0;

    &:before {
      background: rgba(0, 0, 0, 0.8);
      content: "";
      height: 100%;
      left: 0;
      opacity: 0.8;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .single-address {
    border: 1px solid rgba(255, 255, 255, 0.25);
    padding: 20px 0;
    margin: 0 0 40px 0;
    transition: all 0.4s ease 0s;

    .forSpace {
      visibility: hidden;
    }

    p {
      color: #fff;
    }

    svg {
      color: #fff;
      font-size: 35px;
      margin-bottom: 20px;
    }
  }
}

.contact-moves {
  padding: 150px 0;

  h2 {
    font-size: 2rem;
  }
  p {
    font-size: $font-1125;
  }

  @media (max-width: 1199px) {
    h2 {
      font-size: 1.6rem;
    }
  }
}

.contact {
  padding: 50px 0;

  h2 {
    text-transform: uppercase;
    color: #5d5d5d;

    span {
      color: $color-orange;
    }
  }

  .text-center {
    p {
      letter-spacing: 3px;
      color: #707070;
    }
  }

  .input-group-text {
    background: transparent;

    img {
      width: 20px;
      opacity: 0.5;
    }

    &#story {
      align-items: flex-start;
    }
  }

  .send-btn {
    display: table;
    margin: 20px auto;
    background-color: #0099cc;
    color: white;
    padding: 10px 30px;
    @include br(0);
  }
}

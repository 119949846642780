.solutions {
  .solutionsTitle {
    background: #f8f9fa;

    .solutionText {
      text-align: center;
      color: $color-darkgray;
      text-transform: uppercase;
      padding: 100px 0;

      h2 {
        font-weight: bold;
        font-size: $font-2250;
      }

      h3 {
        font-size: $font-2250;
      }

      @media (max-width: 375px) {
        h3 {
          font-size: $font-1125;
        }
        h2 {
          font-weight: bold;
          font-size: $font-15;
        }
      }
    }
  }

  .ourSolutions {
    .solutionBlock {
      // background-image: url('https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg');
      background-size: cover;
      background-position: center;
      height: 550px;
      width: 100%;

      @media (max-width: 1440px) {
        height: 450px;
      }

      @media (max-width: 991px) {
        height: 350px;
      }

      .col-lg-6 {
        background: rgba(255, 255, 255, 0.85);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .textContainer {
          padding: 0 100px;

          h2 {
            font-weight: bold;
            font-size: $font-2250;

          }

          p {
            font-size: 1.3rem;
          }
          @media (max-width: 1440px) {
            padding: 0 50px;
            h2{
              font-size: $font-2;
            }
            p{
              font-size: 1.125rem;
            }
          }
          @media (max-width: 1132px) {
            padding: 0 50px;
            h2{
              font-size: 1.5rem;
            }
            p{
              font-size: 1rem;
            }
          }
          @media (max-width: 991px) {
            padding: 20px 10px;
            h2{
              font-size: 2rem;
            }
            p{
              font-size: 1.2rem;
            }
          }
          @media (max-width: 375px) {
            padding: 20px 10px;
            h2{
              font-size: 1.4rem;
            }
            p{
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

#services {
  .solutionBlock {
    height: 340px;

    @media (max-width: 1440px) {
      height: 280px;
    }

    @media (max-width: 991px) {
      height: 240px;
    }

    .textContainer {
      padding: 0 30px;

      h2 {
        font-size: 2.3rem;
      }

      p {
        font-size: 1.3rem;
      }

      @media (max-width: 1440px) {
        h2 {
          font-size: 2rem;
        }
        p {
          font-size: 1.2rem;
        }
      }

      @media (max-width: 1129px) {
        h2 {
          font-size: 1.8rem;
        }
        p {
         font-size: 1rem;
        }
      }

      @media (max-width: 991px) {
        p {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.our-culture {
  padding: 105px 0;

  > div:first-of-type {
    padding: 0 0 105px;

    h2 {
      font-size: 2.375rem;
      color: $color-darkgray;
      max-width: 65%;
    }

    @media (max-width: 1199px) {
      h2 {
        font-size: 2rem;
      }
    }

    @media (max-width: 991px) {
      h2 {
        font-size: 1.8rem;
      }
    }
  }
}

.portfolio-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  @media (max-width: 991px) {
    justify-content: flex-start;
    margin-top: 15px;
  }
}

.portfolio {
  .portfolio-filters {
    margin-bottom: 50px;

    ul {
      display: table;
      margin: 30px auto;

      li {
        display: inline-block;
        margin: 30px;
        cursor: pointer;

        span {
          img {
            width: 45px;
            opacity: 0.5;
          }
        }

        &.active {
          span {
            img {
              opacity: 1;
            }
          }
        }

        &:hover {
          span {
            img {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  .portfolio-list {
    .portfolio-block {
      position: relative;
      background-size: cover;
      background-position: center center;
      height: 432px;
      margin-bottom: 30px;
      display: block;

      .portfolio-layout {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 0 30px 30px;
        opacity: 0;
        transition: all 0.3s;

        h2, p {
          text-transform: uppercase;
        }
      }

      &:hover {
        .portfolio-layout {
          opacity: 1;

        }
      }
    }
  }
}

.portfolio-details {
  .portfolio-title {
    text-align: center;
    text-transform: uppercase;
    padding: 20px 0;

    h4 {
      text-decoration: underline;
      a{
        color: inherit;
      }
    }

    h2 {
      font-weight: bold;
    }
  }

  .portfolio-bg {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: center;

    .layout {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.5);
      color: #b6aeaa;
      padding: 0 30px 30px;
      opacity: 0;
      transition: all 0.3s;


      a {
        border: 1px solid gray;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 108px;
        width: 108px;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    &:hover {
      .layout {
        opacity: 1;
      }
    }

    @media (max-width: 991px) {
      height: 400px;
    }
  }

  .details-container {
    padding: 20px 0;

    .details {
      display: flex;
      align-items: baseline;

      .icon {

        padding: 20px;

        img {
          width: 50px;
        }
      }

      .techName {
        ul {
          margin-bottom: 0;

          li {
            display: inline-block;
            padding: 0 8px 8px;

            &:first-of-type {
              padding-left: 0;
            }

            img {
              width: 40px;
            }
          }
        }
      }
    }
  }

  .container {
    .description {
      p {
        font-size: 1.3rem;
      }
    }
  }
}

.gallery {
  .thumbnails {
    margin: 0 auto;
    display: table;

    img {
      width: 150px;
    }
  }
}

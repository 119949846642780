.tellUs {

  background-color: #178aca;
  padding: 110px 0;
  color: white;

  p {
    margin-bottom: 0;
    font-size: 1.6rem;
  }

  h2 {
    text-transform: uppercase;
    font-size: 1.8rem;
  }

  @media (max-width: 1199px) {
    padding: 60px 0;
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 991px) {

  }
}

.technology-block {

  .block-img {
    height: 150px;
    padding: 25px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-height: 100%;
    }
  }
}
